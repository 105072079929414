<template>
	<div class="page-container">
		<Lang v-if="showLang" :langList="langList" @change="changeLang"></Lang>
		<div class="img-wrap" id="imgWrap">
			<img class="bg-img" :src="homeImg" alt="" />
		</div>
		<div class="bottom-wrap-ht" @click="handleCustom">
			<img class="btn-img" :src="btnImg" alt="" />
		</div>
		<div class="bottom-wrap-ht-xieyi" v-if="showLang">
			<div class="checkBox">
				<van-checkbox v-model="agreementChecked" shape="square" icon-size="14px" checked-color="#29c4da">
				</van-checkbox>
			</div>
			<div class="title" id="protocol" @click="lookAgreement">
				{{ $t("C.UploadTips0") }}<span
					style="color: #29c4da;">{{mid == 1? $t("C.UploadTips2_1"): $t("C.UploadTips2_2")}}</span>
			</div>
		</div>

		<!-- 提示 -->
		<Loading v-show="isLoading" :message="message"></Loading>
	</div>
</template>

<script type="text/ecmascript-6">
	import {
		mapMutations
	} from "vuex";
	import Loading from "components/loading/loading";
	import Lang from "components/lang/lang";
	import api from "api/allApi";
	export default {
		data() {
			return {
				isWeiXinFlag: true, // 判断是否是微信浏览器
				isLoading: false,
				message: '',
				mid: 1,
				devSn: '',

				// 是否加载子组件 语言
				showLang: false,
				// 首页配置数据
				langList: [],
				// 首页图片
				homeImg: '',
				// 首页按钮
				btnImg: '',
				// 首页协议
				protocol: '',
				// 是否勾选协议
				agreementChecked: true
			};
		},
		components: {
			Loading,
			Lang
		},
		mounted() {
			// 高度适配
			var height = document.documentElement.clientHeight;
			this.isWeiXinFlag = this.isWeiXin();
			if (this.isWeiXinFlag == true) {
				if (height <= 553) {
					document.getElementById("imgWrap").style.maxHeight = "59%";
				} else {
					document.getElementById("imgWrap").style.maxHeight = "68%";
				}
			} else {
				if (height <= 553) {
					document.getElementById("imgWrap").style.maxHeight = "59%";
				} else {
					document.getElementById("imgWrap").style.maxHeight = "58%";
				}
			}
			let devSn = this.$route.query.sn || ""; // 设备sn
			let mid = this.$route.query.mid || ""; // 材质编码
			if (devSn == "" || mid == "") {
				this.message = this.$t("C.SnDisabled");
				this.isLoading = true;
			} else {
				this.setDevSn(devSn);
				this.setMid(mid);
				this.devSn = devSn;
				this.mid = mid;
				localStorage.setItem("devSn", devSn);
				localStorage.setItem("mid", mid);
				// 通过sn和mid查询客户首页配置
				this.$api
					.get(this, api.queryPrinterMobileHome, {
						deviceSn: this.devSn
					})
					.then((res) => {
						if (res.code == 0) {
							this.langList = res.data.homeRuleRespVOS;
							if (this.langList.length > 0) {
								// 挂载语言
								this.showLang = true;
							} else {
								this.message = this.$t("C.SnDisabled");
								this.isLoading = true;
							}
						} else {
							this.message = this.$t("C.SnDisabled");
							this.isLoading = true;
						}
					});
					if(this.mid == 2) {
						this.$api.get(this, api.hotPrintImgParams, {id:2}).then((res) => {
							if(res.code == 0) {
								localStorage.setItem("printerFlipHot", res.data.printerFlip);
								localStorage.setItem("topMarginHot", res.data.topMargin);
								localStorage.setItem("leftMarginHot", res.data.leftMargin);
								localStorage.setItem("printerImgHot", res.data.printerImg);
							} else {
								this.message = this.$t("C.SnDisabled");
								this.isLoading = true;
							}
						});
					}
			}
		},
		methods: {
			// 语言、首页图片、首页按钮、首页协议
			changeLang(param0, param1, param2, param3) {
				if (param1) {
					this.homeImg = param1;
				}
				if (param2) {
					this.btnImg = param2;
				}
				if (param3) {
					this.protocol = param3;
					localStorage.setItem("protocol", param3);
				}
			},

			// 查看定制协议
			lookAgreement() {
				let bLang = window.localStorage.getItem("bLang");
				this.$router.push({
					path: "/customerProtocol",
					query: {
						mid: this.mid,
						bLang: bLang,
						devSn: this.devSn,
						protocol: this.protocol,
						back: 'home'
					}
				});
			},

			// 立即定制
			handleCustom() {
				if (this.agreementChecked) {
					this.customNext();
				} else {
					this.$toast(this.$t("C.ReadAndCheckTheAgreement"));
				}
			},

			// 定制回调
			customNext() {
				var mid = localStorage.getItem("mid");
				var sn = localStorage.getItem("devSn");
				if (sn == "" || sn == undefined || sn == null) {
					this.message = this.$t("C.NoSn");
					this.isLoading = true;
				} else if (mid == "" || mid == undefined || mid == null) {
					this.message = this.$t("C.NoMid");
					this.isLoading = true;
				} else {
					this.$api
						.get(this, api.customerStatus, {
							deviceSn: this.devSn
						})
						.then((res) => {
							if (res.code == 0) {
								if (res.data.sellerStatus == 1) {
									if (res.data.equipmentStatus == 1) {
										if (Number(mid) === 1) {
											// 贴纸打印机
											this.$router.push({
												path: "/custom",
											});
										} else if (Number(mid) === 2) {
											// 热转印机
											this.$router.push({
												path: "/hotCustom",
											});
										} else {
											this.message = this.$t("C.NoMid");
											this.isLoading = true;
										}
									} else {
										this.message = this.$t("C.SnDisabled");
										this.isLoading = true;
									}
								} else {
									this.message = this.$t("C.CusDisabled");
									this.isLoading = true;
								}
							} else {
								if (res.msg === "客户信息不存在") {
									this.message = this.$t("C.NoCustomerInfo");
								} else {
									this.message = this.$t("C.SnDisabled");
								}
								this.isLoading = true;
							}
						});
				}
			},

			// 存取路由信息
			...mapMutations({
				setDevSn: "SET_DEVSN",
				setMid: "SET_MID",
			}),

			// 判断是否为微信端打开的
			isWeiXin() {
				var ua = window.navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					return true;
				} else {
					return false;
				}
			}
		},
	};
</script>

<style rel="stylesheet/stylus" lang="stylus" scoped>
	@import '~common/styles/mixin.styl';
	@import '~common/styles/variable.styl';

	.page-container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background: linear-gradient(359deg, #abe4ef 0%, #61d0e1 30%, #14c0d8 100%);

		.img-wrap {
			max-height: 63%;

			.bg-img {
				width: 100%;
			}
		}

		.bottom-wrap-ht {
			position: fixed;
			bottom: 90px;
			display: flex;
			align-items: center;
			justify-content: center;

			.btn-img {
				width: 40%;
			}
		}

		.bottom-wrap-ht-xieyi {
			position: fixed;
			bottom: 50px;
			left: 0;
			right: 0;
			width: 65%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			font-size: 12px;

			.title {
				margin-left: 3px;
				margin-bottom: 1px;
			}
		}

		.icon-box {
			position: relative;
			top: 6.5vh;

			.down-icon {
				margin: 0 auto;
				width: 5rem;
				height: 5rem;
				image-d('down');
				background-size: 100% 100%;
			}
		}

		.btn-wrap {
			position: relative;
			width: 100%;
			left: 0;
			top: 5.5rem;
			padding: 0 4rem;
			display: flex;
			justify-content: center;
			box-sizing: border-box;

			.btn {
				btn-normal-h();
				width: 100%;
				lineHeight(5rem);
			}
		}
	}
</style>
