<template>
	<div class="lang-content">
		<el-select class="lang" v-model="lang" @change="langChange(lang)" placeholder="请选择">
			<el-option v-for="item in langList" :key="item.languageCode" :label="item.language"
				:value="item.languageCode" />
		</el-select>
	</div>
</template>

<script>
	export default {
		name: "Lang",
		props: [
			"langList"
		],
		data() {
			return {
				lang: this.$i18n.locale
			};
		},

		mounted() {
			// 加载语言数据
			let flag = 0;
			if (this.langList.length > 0) {
				console.log(this.langList);
				this.langList.forEach(item => {
					if (item.isDefault == 1) {
						this.lang = item.languageCode;
						this.$i18n.locale = this.lang;
						window.localStorage.setItem('bLang', this.lang);
						let bLang = window.localStorage.getItem('bLang');
						if (bLang === 'zh-cn') {
							document.title = "手机美妆定制";
						} else if (bLang === 'zh-tw') {
							document.title = "手機美妝定制";
						} else if (bLang === 'he') {
							document.title = "יפה בטלפון.";
						} else if (bLang === 'es') {
							document.title = "Personalización de belleza móvil";
						} else if (bLang === 'it') {
							document.title = "Personalizzazione della bellezza mobile";
						} else if (bLang === 'uk') {
							document.title = "Mobile Beauty Customization";
						} else if (bLang === 'fr') {
							document.title = "Personnalisation beauté mobile";
						} else if (bLang === 'ar') {
							document.title = "الهاتف المحمول جمال المكياج حسب الطلب";
						} else if (bLang === 'jap') {
							document.title = "スキンシールカスタマイズアプリ";
						} else {
							document.title = "CELLPHONE BEAUTIFY";
						}
						this.$emit('change', this.lang, item.homeImg, item.btnImg, item.homeRuleProtocolRespVOS[0]
							.protocol);
						localStorage.setItem('homeRuleProtocolList', JSON.stringify(item.homeRuleProtocolRespVOS));
					}
					if (this.lang == item.languageCode) {
						flag++;
					}
				});
				if (flag == 0) {
					var item = this.langList[0];
					this.lang = item.languageCode;
					this.$i18n.locale = this.lang;
					window.localStorage.setItem('bLang', this.lang);
					let bLang = window.localStorage.getItem('bLang');
					if (bLang === 'zh-cn') {
						document.title = "手机美妆定制";
					} else if (bLang === 'zh-tw') {
						document.title = "手機美妝定制";
					} else if (bLang === 'he') {
						document.title = "יפה בטלפון.";
					} else if (bLang === 'es') {
						document.title = "Personalización de belleza móvil";
					} else if (bLang === 'it') {
						document.title = "Personalizzazione della bellezza mobile";
					} else if (bLang === 'uk') {
						document.title = "Mobile Beauty Customization";
					} else if (bLang === 'fr') {
						document.title = "Personnalisation beauté mobile";
					} else if (bLang === 'ar') {
						document.title = "الهاتف المحمول جمال المكياج حسب الطلب";
					} else if (bLang === 'jap') {
						document.title = "スキンシールカスタマイズアプリ";
					} else {
						document.title = "CELLPHONE BEAUTIFY";
					}
					this.$emit('change', this.lang, item.homeImg, item.btnImg, item.homeRuleProtocolRespVOS[0].protocol);
					localStorage.setItem('homeRuleProtocolList', JSON.stringify(item.homeRuleProtocolRespVOS));
				}
			}
		},
		methods: {
			// 语言改变
			langChange: function(item) {
				this.$i18n.locale = item;
				window.localStorage.setItem('bLang', item);
				let bLang = window.localStorage.getItem('bLang');
				if (bLang === 'zh-cn') {
					document.title = "手机美妆定制";
				} else if (bLang === 'zh-tw') {
					document.title = "手機美妝定制";
				} else if (bLang === 'he') {
					document.title = "יפה בטלפון.";
				} else if (bLang === 'es') {
					document.title = "Personalización de belleza móvil";
				} else if (bLang === 'it') {
					document.title = "Personalizzazione della bellezza mobile";
				} else if (bLang === 'uk') {
					document.title = "Mobile Beauty Customization";
				} else if (bLang === 'fr') {
					document.title = "Personnalisation beauté mobile";
				} else if (bLang === 'ar') {
					document.title = "الهاتف المحمول جمال المكياج حسب الطلب";
				} else if (bLang === 'jap') {
					document.title = "スキンシールカスタマイズアプリ";
				} else {
					document.title = "CELLPHONE BEAUTIFY";
				}
				this.langList.forEach(item2 => {
					if (item2.languageCode == item) {
						this.$emit('change', item, item2.homeImg, item2.btnImg, item2.homeRuleProtocolRespVOS[0]
							.protocol);
						localStorage.setItem('homeRuleProtocolList', JSON.stringify(item2
							.homeRuleProtocolRespVOS));
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.lang-content {
		.lang {
			position: absolute;
			right: 0.6rem;
			top: 0.6rem;
			width: 110px;
			z-index: 99999;

			/deep/.el-input__inner {
				height: 30px;
				line-height: 30px;
			}

			/deep/.el-input__icon {
				line-height: 30px;
			}
		}
	}
</style>
